<template>
  <div>
    <div>
      <b-card-actions
        title="Filters"
        action-collapse
        collapsed
      >
        <b-row>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="Ref No"
              label-for="v-ref-no"
            >
              <b-form-input
                id="v-ref-no"
                v-model="filters['ref_no']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="Created At"
              label-for="created-at"
            >
              <div class="position-relative">
                <flat-pickr
                  id="created-at"
                  v-model="filters['created_at']"
                  :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                  class="form-control"
                />
                <div
                  v-if="filters['created_at']"
                  class="flatpickr-cancel"
                  @click="clearDate('created_at')"
                >
                  <feather-icon icon="XIcon" />
                </div>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="Warehouse"
              label-for="warehouse"
            >
              <v-select
                id="warehouse"
                v-model="filters['warehouse_id']"
                :options="warehouse"
                :reduce="option => option.id"
                label="name"
                placeholder="Select Warehouse"
              >
                <template v-slot:option="option">
                  {{ option.name }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="Status"
              label-for="deposit_status"
            >
              <v-select
                v-model="filters['status']"
                :options="statusOptions"
                :reduce="option => option.key"
                label="name"
                placeholder="Select Status"
              >
                <template v-slot:option="option">
                  {{ option.name }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Apply and Clear Filters -->
        <b-row class="mt-1">
          <b-col>
            <filter-buttons
              :filter-data="filters"
              :apply-filters="applyFilters"
              :clear-filters="clearFilters"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
    <div>
      <b-card
        no-body
      >
        <div
          class="m-2"
        >
          <b-row class="d-sm-flex align-items-start">
            <b-col
              md="12"
              lg="8"
              class="d-block d-sm-flex flex-wrap justify-content-start align-items-center mb-1 mb-md-0"
            >
              <b-button
                v-can="'branch-finance-paid'"
                :disabled="depositSelected.length === 0"
                class="mr-1 add-margin-bottom"
                variant="success"
                @click="onClickMakePayment()"
              >
                Make Payment
              </b-button>
              <b-button
                v-can="'branch-finance-approve'"
                :disabled="depositSelected.length === 0"
                class="mr-1 add-margin-bottom"
                variant="info"
                @click="onClickApprove()"
              >
                Approve
              </b-button>
              <b-button
                v-can="'branch-finance-reject'"
                :disabled="depositSelected.length === 0"
                class="mr-1 add-margin-bottom"
                variant="danger"
                @click="onClickReject()"
              >
                Reject
              </b-button>
              <b-button
                v-can="'branch-finance-create'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="custom-button-color add-margin-bottom"
                @click="onClickNewDeposit()"
              >
                <feather-icon icon="PlusIcon" />
                Add New Deposit
              </b-button>
            </b-col>
            <b-col
              class="d-flex justify-content-end align-items-center mb-1 mb-md-0"
            >
              <ReportTableButtons
                :items="items"
                :json_fieldz="json_fields"
                :name="`BranchDeposit - Page ${page}.xls`"
                :bulkname="`BranchDeposit.xls`"
                :fetch="fetchDataForBranchNoPagination"
                @refresh="onClickRefresh"
              />
            </b-col>
          </b-row>
          <b-row>
            <div class="font-weight-bolder text-muted ml-1 mt-1">
              {{ `${depositSelected.length} record${depositSelected.length === 1 ? '':'s'}` }} selected
            </div>
          </b-row>
        </div>

        <!-- table -->
        <b-overlay
          id="overlay-background"
          blur="10px"
          opacity="0.30"
          rounded="sm"
          variant="light"
        >
          <b-table
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            hover
            responsive
            striped
            show-empty
            sticky-header="100vh"
            class="hide-vertical-scroll"
          >
            <template #empty>
              <TableDataFetching
                :rows="items"
                :data-loading="dataLoading"
              />
            </template>
            <template #head(id)>
              <div class="d-flex align-items-start">
                <div>
                  <b-form-checkbox
                    id="selectAllCheckbox"
                    v-model="selectAllCheckbox"
                    name="selectAllCheckbox"
                    @change="onClickSelectAllCheckbox()"
                  />
                </div>
              </div>
            </template>
            <template #cell(id)="data">
              <div class="d-flex align-items-start">
                <div>
                  <div class="font-weight-bolder">
                    <b-form-checkbox
                      v-model="depositSelected"
                      :value="data.item.id"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template #cell(ref_no)="data">
              <div class="d-flex align-items-center">
                <b-avatar
                  :text="avatarText(data.item.ref_no)"
                  size="32"
                  variant="light-primary"
                />
                <div class="ml-1">
                  <div
                    class="font-weight-bolder text-primary"
                    @click="onClickRef(data.item.id)"
                  >
                    {{ data.value }}
                  </div>
                  <div class="font-small-2">
                    {{ __dateTimeFormatter(data.item.created_at) }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(depositable_amount)="data">
              <span>{{ __numberWithCommas(data.value, true) }}</span>
            </template>
            <template #cell(deposit_amount)="data">
              <span>{{
                data.item.expenses_total > 0.00
                  ? __numberWithCommas(data.item.depositable_amount_after_expenses, true)
                  : __numberWithCommas(data.value, true)
              }}</span>
            </template>
            <template #cell(expenses_total)="data">
              <span>{{ __numberWithCommas(data.value, true) }}</span>
            </template>
            <template #cell(rider_commission)="data">
              <span>{{ __numberWithCommas(data.value, true) }}</span>
            </template>
            <template #cell(deposited_date)="data">
              {{ __dateTimeFormatter(data.value) || '---' }}
            </template>
            <template #cell(approved_date)="data">
              {{ __dateTimeFormatter(data.value) || '---' }}
            </template>
            <template #cell(finance_current_status.key)="data">
              <finance-status-badge
                :status-key="data.value"
                :finance-status="statusOptions"
              />
              <div
                v-if="data.item.is_reversed_deposit"
                class="mt-1 d-flex justify-content-center"
              >
                <b-tooltip
                  :target="data.item.id.toString()"
                  triggers="hover"
                  variant="danger"
                >
                  Rejected Once
                </b-tooltip>
                <b-avatar
                  :id="data.item.id.toString()"
                  size="20"
                  style="background-color: #ffb5b5"
                >
                  <feather-icon
                    icon="ChevronsLeftIcon"
                    class="text-body align-middle"
                  />
                </b-avatar>
              </div>
            </template>
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                :class="items.length !== 1 ? 'dropdown-no-scroll':''"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="12"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-if="data.item.receipt_url"
                  :href="`${bucketUrl}${data.item.receipt_url}`"
                  target="_blank"
                >
                  <feather-icon icon="EyeIcon" />
                  View Payment Slip
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.is_reversed_deposit"
                  @click="onClickShowRejectionReasons(data.item.id)"
                >
                  <feather-icon icon="BookIcon" />
                  Rejection Reasons
                </b-dropdown-item>
                <b-dropdown-item
                  v-can="'branch-finance-show'"
                  :to="{ name: 'branch-deposit-orders', params: { id: data.item.id } }"
                >
                  <feather-icon icon="ToolIcon" />
                  View Orders
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.finance_current_status.key == 'f_key_1'"
                  v-can="'branch-finance-show'"
                  :to="{ name: 'branch-deposit-expenses', params: { id: data.item.id, finance_key: data.item.finance_current_status.key } }"
                >
                  <feather-icon icon="ToolIcon" />
                  Add Expenses
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.finance_current_status.key == 'f_key_2'"
                  v-can="'branch-finance-show'"
                  :to="{ name: 'branch-deposit-expenses', params: { id: data.item.id, finance_key: data.item.finance_current_status.key } }"
                >
                  <feather-icon icon="ToolIcon" />
                  View Expenses
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.finance_current_status.key !== 'f_key_1'"
                  v-can="'rider-finance-show'"
                  @click="onClickPrint(data.item.id)"
                >
                  <feather-icon icon="PrinterIcon" />
                  Print Deposit Note
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-overlay>

        <div class="mx-2 mb-2">
          <b-row
            cols="12"
          >
            <b-col
              class="d-flex align-items-center justify-content-sm-start"
              md="3"
            >
              <label class="width-75">Per page</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block width-100"
              />
            </b-col>
            <!-- Pagination -->
            <b-col
              class="d-flex align-items-center justify-content-sm-end"
              md="9"
            >
              <span class="text-muted pagination-text mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
              <b-pagination
                v-model="page"
                :total-rows="(meta.total)? meta.total : 0"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    class="next-icon"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    class="next-icon"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
    <make-deposite-action ref="make_deposit_action_modal" />
    <approve-action ref="approve_action_modal" />
    <reject-action ref="reject_action_modal" />
    <rejection-details ref="rejection_detail_modal" />
    <print-branch-deposit ref="print_deposit_modal" />
  </div>
</template>

<script>
import {
  BAvatar,
  BFormCheckbox,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  VBModal,
  BOverlay,
  VBTooltip,
  BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { bucketUrl } from '@/constants/config'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import flatPickr from 'vue-flatpickr-component'
import FinanceStatusBadge from '@core/components/status/FinanceStatusBadge.vue'
import MakeDepositeAction from '@/components/FinanceModuleForms/ActionForms/MakeDepositeAction.vue'
import ApproveAction from '@/components/FinanceModuleForms/ActionForms/ApproveAction.vue'
import RejectAction from '@/components/FinanceModuleForms/ActionForms/RejectAction.vue'
import PrintBranchDeposit from '@/components/FinanceModuleForms/Print/PrintBranchDepositModal.vue'
// eslint-disable-next-line import/extensions
import RejectionDetails from '@/components/FinanceModuleForms/InfoModals/RejectionDetailModal'
import store from '@/store/index'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const ResourceRepository = RepositoryFactory.get('resource')
const FinanceRepository = RepositoryFactory.get('finance')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    flatPickr,
    ApproveAction,
    RejectAction,
    MakeDepositeAction,
    vSelect,
    BTooltip,
    BOverlay,
    BCard,
    BTable,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BAvatar,
    BPagination,
    BButton,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BCardActions,
    FinanceStatusBadge,
    PrintBranchDeposit,
    RejectionDetails,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      bucketUrl,
      deselect: true,
      avatarText,
      depositSelected: [],
      filters: {},
      dataLoading: false,
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      meta: {},
      dir: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id',
          sortable: false,
        },
        {
          key: 'ref_no',
          label: 'REF',
          thClass: 'text-center',
          sortable: true,
        },
        {
          key: 'warehouse.name',
          label: 'Warehouse',
          sortable: true,
        },
        {
          key: 'depositable_amount',
          label: 'Collected COD',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'expenses_total',
          label: 'Expenses Total',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'rider_commission',
          label: 'Rider Commission',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'deposit_amount',
          label: 'Depositable Amount',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'deposited_date',
          label: 'Deposited Date',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'approved_date',
          label: 'Approved Date',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'finance_current_status.key',
          label: 'Status',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        { key: 'finance_current_status.key', label: 'Status', sortable: true },
        { key: 'action', label: 'Action', sortable: false },
      ],
      items: [],
      warehouse: [],
      operationOption: [
        { title: 'Make Payment' },
        { title: 'Approve' },
      ],
      json_fields: {
        REF: 'ref_no',
        'Deposit Date': {
          field: 'created_at',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        Warehouse: {
          field: 'warehouse.name',
          callback: value => `"${value}"`,
        },
        'Collected COD': 'depositable_amount',
        'Expenses Total': {
          field: 'expenses_total',
          callback: value => (value !== null && value !== '' ? value : '0'),
        },
        'Rider Commssion': 'rider_commission',
        'Deposit Amount': 'depositable_amount_after_expenses',
        'Deposited Date': {
          field: 'deposited_date',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        'Approved Date': {
          field: 'approved_date',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        Status: 'status.name',
      },
      selectedOperation: 0,
      // end
      statusOptions: [],
      selectAllCheckbox: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchDataForBranch()
      },
    },
    deep: true,
    page() {
      this.fetchDataForBranch()
    },
    perPage() {
      this.fetchDataForBranch()
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.fetchDataForBranch()
    this.readDataforWarehouse()
    this.statusOptions = JSON.parse(localStorage.getItem('finance_statuses'))
  },
  methods: {
    async fetchDataForBranch() {
      this.dataLoading = true
      this.items = []
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await FinanceRepository.getBranchFinanceDepositList(this.page, this.filterQuery, this.perPage, this.sort))
        this.items = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchDataForBranchNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await FinanceRepository.getBranchFinanceDepositListNoPagination(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async readDataforWarehouse() {
      try {
        const { data } = (await ResourceRepository.getBranchListDropdown()).data
        this.warehouse = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    handleChangePage(page) {
      this.page = page
      this.fetchDataForBranch()
    },
    applyFilters() {
      this.fetchDataForBranch()
      this.deselectAll()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchDataForBranch()
      })
    },

    selectAll() {
      this.depositSelected = this.items.map(n => n.id)
    },
    deselectAll() {
      this.depositSelected = []
      this.deselect = true
    },
    onClickMakePayment() {
      this.$refs.make_deposit_action_modal.openModal(this.depositSelected, 'branch')
    },
    onClickApprove() {
      this.$refs.approve_action_modal.openModal(this.depositSelected, 'branch')
    },
    onClickReject() {
      this.$refs.reject_action_modal.openModal(this.depositSelected, 'branch')
    },
    onClickNewDeposit() {
      this.$router.push({ name: 'add-new-branch-deposit' })
    },
    onClickShowRejectionReasons(id) {
      this.$refs.rejection_detail_modal.openModal(id, 'branch')
    },
    onClickRefresh() {
      this.fetchDataForBranch()
      this.deselectAll()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
    onClickPrint(id) {
      this.$refs.print_deposit_modal.openModal(id)
    },
    onClickRef(order) {
      this.$router.push({
        name: 'branch-deposit-orders',
        params: {
          id: order,
        },
      })
    },
    onClickSelectAllCheckbox() {
      if (this.selectAllCheckbox) {
        // If the "Select All" checkbox is checked, select all waybill numbers.
        this.depositSelected = this.items.map(n => n.id)
      } else {
        this.depositSelected = []
      }
    },
  },
}
</script>
<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select.scss';
@import '../../@core/scss/vue/libs/vue-flatpicker.scss';
.add-margin-bottom {
    margin-bottom: 20px;
}
@media (max-width: 767px) {
  .add-margin-bottom {
    width: 100%;
  }
  .pagination-text {
    font-size: 0.75rem;
  }
}
</style>
