<template>
  <!--      Modal Actual -->
  <b-modal
    id="print-deposit-note"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="Print Deposit Note"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <section>
        <b-card
          id="invoice-preview-card"
          no-body
          class="invoice-preview-card"
        >
          <b-card-body
            class="invoice m-2"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                class="pb-1"
              >
                <h4 class="pb-1">
                  Branch Deposit Note
                </h4>
                <h6
                  v-if="allData.branch"
                >
                  Branch Name:  {{ allData.branch.name }}
                </h6>
                <h6
                  v-else
                >
                  Branch Name:  N/A
                </h6>
                <h6>
                  Deposit Date:  {{ allData.deposit_date }}
                </h6>
                <h6>
                  Status:  {{ allData.status.name }}
                </h6>
              </b-col>
            </b-row>
            <b-table
              striped
              bordered
              responsive
              :items="rows"
              :fields="fields"
              show-empty
            />
            <b-row>
              <b-col
                cols="12"
                md="12"
                class="pt-1"
              >
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Total Number of Orders:
                        </td>
                        <td><span>{{ allData.orders.amounts.total_order_count || '0' }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Total Collected COD:
                        </td>
                        <td><span>Rs.{{ __numberWithCommas(allData.orders.amounts.total_collected_cod) || '0' }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Total Expenses:
                        </td>
                        <td><span>Rs.{{ __numberWithCommas(allData.expenses_total) || '0' }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Total Commission:
                        </td>
                        <td><span>Rs.{{ __numberWithCommas(allData.orders.amounts.total_commission) || '0' }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <h6 class="font-weight-bold">
                            NET Payable:
                          </h6>
                        </td>
                        <td>
                          <span class="font-weight-bold">Rs.{{ allData.expenses_total > 0.00 ? __numberWithCommas(calculatePayable() ) : __numberWithCommas(allData.orders.amounts.net_payable, true) }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="4"
        >
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-success"
            class="mb-75"
            block
            @click="printDepositNote"
          >
            <feather-icon
              icon="PrinterIcon"
            />
            Print
          </b-button>
        </b-col>
      </section>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BCard,
  BButton,
  BModal,
  BCol,
  BCardBody,
  BRow,
  BOverlay,
  VBModal,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const FinanceRepository = RepositoryFactory.get('finance')

function initialState() {
  return {
    open: false,
    modalLoading: false,
    id: '',
    fields: [
      {
        key: 'waybill_number',
        label: 'Waybill Number',
      },
      {
        key: 'collected_cod',
        label: 'Collected COD Amount',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'commission',
        label: 'Commission',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
    ],
    rows: [],
    allData: {
      branch: {},
    },
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BCard,
    BButton,
    BModal,
    BCol,
    BCardBody,
    BRow,
    BOverlay,
    BTable,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(id) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.id = id
      this.getBranchDepositNote()
    },
    async getBranchDepositNote() {
      this.modalloading = true
      try {
        const { data } = (await FinanceRepository.getBranchDepositNote(this.id)).data
        this.rows = data.orders.order_details
        this.allData = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    printDepositNote() {
      const modal = document.getElementById('invoice-preview-card')
      const cloned = modal.cloneNode(true)
      let section = document.getElementById('print')

      if (!section) {
        section = document.createElement('div')
        section.id = 'print'
        document.body.appendChild(section)
      }

      section.appendChild(cloned)
      window.print()
      section.innerHTML = ''
    },
    calculatePayable() {
      const totalCollectedCod = this.allData.orders.amounts.net_payable || 0
      const expensesTotal = this.allData.expenses_total || 0
      const payable = totalCollectedCod - expensesTotal
      return payable >= 0 ? payable : 0
    },
  },
}
</script>
<style lang="scss">
@page {
  width: 80mm;
}
@media screen {
  #print {
    display: none;
  }
}
@media print {
  body * {
    visibility:hidden;
  }
  #print, #print * {
    visibility:visible;
  }
  #print {
    position:absolute;
    left:0;
    top:0;
    width: 100%;
  }
}
</style>
<style scoped>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
</style>
